import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "preview"];
  static values = {
    directUploadUrl: String,
    updateUrl: String,
    fieldName: String,
  };

  connect() {
    this.isUploading = false;
    this.MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
  }

  triggerFileInput(event) {
    event.preventDefault();
    if (this.isUploading) return;
    this.inputTarget.click();
  }

  reset(event) {
    event.preventDefault();
    if (this.isUploading) return;

    const formData = new FormData();
    formData.append(this.fieldNameValue, "");

    this.updateServer(formData);
  }

  upload(event) {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > this.MAX_FILE_SIZE) {
      this.showError("Maximum file size is 2MB");
      this.inputTarget.value = "";
      return;
    }

    this.isUploading = true;
    const upload = new DirectUpload(file, this.directUploadUrlValue, this);

    upload.create((error, blob) => {
      if (error) {
        this.uploadError(error);
      } else {
        const formData = new FormData();
        formData.append(this.fieldNameValue, blob.signed_id);
        this.updateServer(formData);
      }
      this.uploadComplete();
    });
  }

  updateServer(formData) {
    fetch(this.updateUrlValue, {
      method: "PATCH",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((html) => {
        if (html && !formData.get(this.fieldNameValue)) {
          Turbo.renderStreamMessage(html);
        }
      })
      .catch((error) => {
        console.error("Update error:", error);
        this.uploadError("Failed to update image");
      });
  }

  updatePreview(url) {
    if (this.hasPreviewTarget) {
      const avatarImg = this.previewTarget.querySelector("img");
      const deleteButton = this.previewTarget.querySelector("button");

      if (avatarImg) {
        avatarImg.src = url;
      } else {
        const img = document.createElement("img");
        img.src = url;
        img.className = "w-[60px] h-[60px] rounded-full object-cover";

        // Clear the preview target while preserving the delete button
        if (deleteButton) {
          this.previewTarget.innerHTML = "";
          this.previewTarget.appendChild(img);
          this.previewTarget.appendChild(deleteButton);
        } else {
          this.previewTarget.innerHTML = "";
          this.previewTarget.appendChild(img);
        }
      }
    }
  }

  uploadError(error) {
    this.isUploading = false;
    this.showError(error || "Upload failed. Please try again.");
  }

  uploadComplete() {
    this.isUploading = false;
  }

  showError(message) {
    console.error(message);
    alert(message);
  }

  // DirectUpload callbacks
  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      // You can add progress indicator here if needed
      console.log(`Upload progress: ${progress}%`);
    });
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    // Show temporary preview before upload completes
    const file = this.inputTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.updatePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
}
