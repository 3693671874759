import Reveal from "@stimulus-components/reveal";

export default class extends Reveal {
  static targets = ["icon"];

  connect() {
    super.connect();
  }

  toggle() {
    super.toggle();
    this.iconTarget.classList.toggle("-rotate-90");
  }
}
