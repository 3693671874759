import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "menu", "icon", "workspaceButton"];

  connect() {
    this.isOpened = false;
    this.isLoading = false;
    this.currentWorkspaceId = this.element.dataset.currentWorkspaceId;
  }

  clear() {
    this.element.reset();
  }

  toggle(event) {
    if (this.isLoading) return;
    event.preventDefault();
    this.isOpened = !this.isOpened;
    this.updateMenu();
  }

  hide(event) {
    if (this.isLoading) return;
    if (!this.element.contains(event.target)) {
      this.isOpened = false;
      this.updateMenu();
    }
  }

  updateMenu() {
    if (this.isOpened) {
      this.menuTarget.classList.remove("hidden");
      this.buttonTarget.classList.add("active");
      this.iconTarget.classList.remove("-rotate-90");
    } else {
      this.menuTarget.classList.add("hidden");
      this.buttonTarget.classList.remove("active");
      this.iconTarget.classList.add("-rotate-90");
    }
  }

  changeWorkspace(event) {
    if (this.isLoading) return;

    const clickedButton = event.currentTarget;
    const workspaceId = clickedButton.dataset.workspaceId;

    if (workspaceId === this.currentWorkspaceId) {
      this.isOpened = false;
      this.updateMenu();
      return;
    }

    this.isLoading = true;

    const spinnerIcon = clickedButton.querySelector(
      '[data-components--workspace-switcher-target="spinnerIcon"]'
    );
    const chevronIcon = clickedButton.querySelector(
      '[data-components--workspace-switcher-target="chevronIcon"]'
    );

    if (spinnerIcon && chevronIcon) {
      chevronIcon.classList.add("hidden");
      spinnerIcon.classList.remove("hidden");
    }
  }

  addWorkspace(event) {
    if (this.isLoading) return;
    window.location.href = "/workspaces/new";
  }
}
