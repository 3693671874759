import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'search', 'options', 'tagTemplate', 'optionTemplate', 'selectedOptions'];
  static values = {
    availableOptions: String,
    selectedOptions: String
  };

  connect() {
    this._handleInput = this._handleInput.bind(this);
    this._handleFocusIn = this._handleFocusIn.bind(this);

    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        this.optionsTarget.classList.add('hidden');
      }
    });
    this.searchTarget.addEventListener('input', this._handleInput);
    this.searchTarget.addEventListener('focusin', this._handleFocusIn);

    // Initialize state
    this.availableOptions = JSON.parse(this.availableOptionsValue);
    this.selectedOptions = JSON.parse(this.selectedOptionsValue);

    this._refreshView();

    // select only selected options
    this.selectedOptions.forEach(option => {
      Array.from(this.inputTarget.options).forEach(inputOption => {
        if (inputOption.value === option.id) {
          inputOption.selected = true;
        }
      });
    });
  }

  disconnect() {
    this.searchTarget.removeEventListener('input', this._handleInput);
    this.searchTarget.removeEventListener('focusin', this._handleFocusIn);
  }

  selectOption(event) {
    const optionId = event.target.dataset.optionValue;
    const selectedOption = this.availableOptions.find(option => option.id === optionId);

    if (selectedOption) {
      // Update state
      this.selectedOptions = [...this.selectedOptions, selectedOption];
      this.availableOptions = this.availableOptions.filter(option => option.id !== optionId);

      // Update select input
      Array.from(this.inputTarget.options).forEach(option => {
        if (option.value === selectedOption.id) {
          option.selected = true;
        }
      });

      // Reset search
      this.searchTarget.value = '';
      this.optionsTarget.classList.add('hidden');

      this._refreshView();

      this.element.dispatchEvent(new Event('submit', { bubbles: true }));
    }
  }

  removeOption(event) {
    const optionId = event.target.closest('.js-tag').dataset.optionId;
    const removedOption = this.selectedOptions.find(option => option.id === optionId);

    if (removedOption) {
      // Update state
      this.selectedOptions = this.selectedOptions.filter(option => option.id !== optionId);
      this.availableOptions = [...this.availableOptions, removedOption];

      // Unselect option in select input
      Array.from(this.inputTarget.options).forEach(option => {
        if (option.value === optionId) {
          option.selected = false;
        }
      });

      this._refreshView();

      this.element.dispatchEvent(new Event('submit', { bubbles: true }));
    }
  }

  _refreshView() {
    // Refresh available options list
    const optionsList = this.optionsTarget.querySelector('ul');

    optionsList.innerHTML = '';
    this.availableOptions.forEach(option => {
      const optionTemplate = this.optionTemplateTarget.content.cloneNode(true);
      const optionButton = optionTemplate.querySelector('button');

      optionButton.innerHTML = optionButton.innerHTML.replace('${name}', option.name);
      optionButton.dataset.optionValue = option.id;

      optionsList.appendChild(optionTemplate);
    });

    // Refresh selected tags
    this.selectedOptionsTarget.innerHTML = '';
    this.selectedOptions.forEach(option => {
      const tag = this.tagTemplateTarget.content.cloneNode(true);
      const tagElement = tag.querySelector('span');

      tagElement.innerHTML = tagElement.innerHTML.replace('${name}', option.name);
      tagElement.dataset.optionId = option.id;

      this.selectedOptionsTarget.appendChild(tag);
    });
  }

  _handleInput(event) {
    this.optionsTarget.classList.remove('hidden');
    this._filterOptions(event);
  }

  _handleFocusIn() {
    this.optionsTarget.classList.remove('hidden');
  }

  _filterOptions(event) {
    const value = event.target.value.toLowerCase();
    this.optionsTarget.querySelectorAll('li').forEach(li => {
      li.classList.toggle('hidden', !li.textContent.toLowerCase().includes(value));
    });
  }
}
