import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    this.inputTarget.value = this.inputTarget.value || "#";
    this.updatePreview();
  }

  updatePreview() {
    let color = this.inputTarget.value;

    // If the field is empty or just contains whitespace, reset to #
    if (!color || color.trim() === "") {
      this.inputTarget.value = "#";
      this.previewTarget.style.backgroundColor = "";
      return;
    }

    // Ensure the input always starts with #
    if (!color.startsWith("#")) {
      color = "#" + color;
    }

    // Limit to 7 characters (#RRGGBB)
    if (color.length > 7) {
      color = color.slice(0, 7);
    }

    this.inputTarget.value = color;

    if (this.isValidColor(color)) {
      this.previewTarget.style.backgroundColor = color;
    } else {
      this.previewTarget.style.backgroundColor = "";
    }
  }

  isValidColor(color) {
    return /^#[0-9A-Fa-f]{6}$/.test(color);
  }

  beforeinput(event) {
    // Prevent input if it would exceed 7 characters
    if (
      this.inputTarget.value.length >= 7 &&
      event.inputType.startsWith("insert") &&
      this.inputTarget.selectionStart === this.inputTarget.selectionEnd // Only if not replacing text
    ) {
      event.preventDefault();
      return;
    }

    // Prevent hashtag deletion
    if (
      event.inputType === "deleteContentBackward" &&
      this.inputTarget.selectionStart <= 1 &&
      this.inputTarget.selectionEnd <= 1
    ) {
      event.preventDefault();
    }
  }
}
