import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'button'];

  connect() {
    document.addEventListener('input-grow-updated', () => {
      this.buttonTarget.classList.remove('opacity-0');
      this.buttonTarget.classList.add('opacity-100');
    });
  }

  disconnect() {
    document.removeEventListener('input-grow-updated', () => {});
  }

  selectAll() {
    this.inputTarget.select();
  }
}
