import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "companyRow", "companyNotFound"];

  connect() {
    this.cacheCompanyNames();
    this.search = this.debounce(this.search.bind(this), 200);
  }

  cacheCompanyNames() {
    this.companies = this.companyRowTargets.map((company) => {
      const name = company
        .querySelector(".company-name")
        .textContent.trim()
        .toLowerCase();
      return { element: company, name };
    });
  }

  search() {
    const searchTerm = this.inputTarget.value.trim().toLowerCase();
    let found = 0;

    this.companies.forEach(({ element, name }) => {
      const isVisible = name.includes(searchTerm);
      element.classList.toggle("hidden", !isVisible);
      if (isVisible) {
        found += 1;
      }
    });

    this.companyNotFoundTarget.classList.toggle("hidden", found !== 0);
  }

  companyRowTargetConnected() {
    this.cacheCompanyNames();
  }

  companyRowTargetDisconnected() {
    this.cacheCompanyNames();
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}
