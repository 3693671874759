import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["csvImport", "leadObjectType"];

  connect() {
    this.updateState();
  }

  updateState() {
    const form = this.element.closest("form");

    if (form.querySelector("#playbook_trigger_source_manual_import").checked) {
      this.csvImportTarget.classList.remove("hidden");
      this.leadObjectTypeTarget.classList.remove("hidden");
    } else {
      this.csvImportTarget.classList.add("hidden");
      this.leadObjectTypeTarget.classList.add("hidden");
    }
  }
}
