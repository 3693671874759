import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["window"];
  static values = {
    skipUrl: String,
    promptName: String,
  };

  connect() {
    enter(this.element);
    enter(this.windowTarget);
  }

  close() {
    if (this.hasSkipUrlValue && this.skipUrlValue && this.skipUrlValue.trim() !== '' && this.hasPromptNameValue) {
      this.skipStep();
    }

    Promise.all([leave(this.element), leave(this.windowTarget)]).then(() => {
      this.element.remove();
      this.modalTurboFrame.src = null;
    });
  }

  escClose(event) {
    if (event.key === "Escape") {
      this.close();
      this.skipStep();
    }
  }

  skipStep() {
    const url = this.skipUrlValue;
    const promptName = this.promptNameValue;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ prompt_name: promptName }),
    });
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
