import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this._handleInput = this._handleInput.bind(this);

    this.element.addEventListener('input', this._handleInput);
    this._updateWidth(this.element);
  }

  _handleInput(event) {
    this._updateWidth(event.target);
  }

  _updateWidth(element) {
    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.whiteSpace = 'pre';
    tempSpan.style.font = window.getComputedStyle(element).font;
    tempSpan.textContent = element.value || element.placeholder || '';
    document.body.appendChild(tempSpan);
    const width = tempSpan.getBoundingClientRect().width;
    document.body.removeChild(tempSpan);
    element.style.width = `${width + 8}px`; // 4px buffer on each side

    document.dispatchEvent(new CustomEvent('input-grow-updated', { detail: { width } }));
  }
}
