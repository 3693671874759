import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tab', 'button'];

  switch(event) {
    const tabId = event.target.dataset.tab;

    this.tabTargets.forEach(tab => {
      if (tab.dataset.tab === tabId) {
        tab.classList.remove('hidden');
      } else {
        tab.classList.add('hidden');
      }
    });

    this.buttonTargets.forEach(button => {
      button.classList.remove('border-primary-500');
    });

    event.target.classList.add('border-primary-500');
  }
}
