import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent", "tabLink", "exportButton"];

  switchTab(event) {
    event.preventDefault();

    this.tabContentTargets.forEach((content) => {
      content.classList.add("hidden");
    });

    this.tabLinkTargets.forEach((link) => {
      link.classList.remove("border-b-2", "border-indigo-600");
    });

    const tab = event.currentTarget.dataset.tab;
    const content = this.tabContentTargets.find(
      (element) => element.dataset.tabContent === tab
    );

    if (content) {
      content.classList.remove("hidden");
    }

    event.currentTarget.classList.add("border-b-2", "border-indigo-600");

    if (this.hasExportButtonTarget) {
      if (tab === "intros") {
        this.exportButtonTarget.classList.remove("hidden");
      } else {
        this.exportButtonTarget.classList.add("hidden");
      }
    }
  }
}
